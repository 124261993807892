<template>
  <MainLayout
    bg-color="white"
    no-content-padding
  >
    <template #content>
      <div class="bg-white">
        <!-- Dev Notes -->
        <div
          v-if="!hideTodos"
          class="bg-white shadow-md fixed bottom-0 right-0 rounded-md p-4"
          style="z-index:9999"
        >
          <div class="w-full h-full relative">
            <div
              class="absolute top-0 right-0 hover:opacity-50 cursor-pointer"
              @click="hideTodos = true"
            >
              Close
            </div>
            <pre>DEV TODOS:
              - [ ] Select col tooltip (graph #s)
              - [ ] Save changes tooltip
              - [ ] Edit preset -> default change shud show save
              - [ ] Color format changes prompt issue (cancel)
              - [ ] select preset + cancel fix
              - [ ] Change arrow color svg
              - [ ] Dedicated btn for drawer open
          </pre>
            <pre class="text-red-500">
            ! Pin disabled, need to think how
            ! need kpi data from zac (title, desc, type)
          </pre>
          </div>
        </div>
        <div class="w-full grid grid-cols-12 min-h-screen">
          <div class="p-2 gap-1 flex flex-col w-full h-full rounded-md border border-border-normal col-span-4">
            <BaseText>Step #1 - Create/Select a Lens</BaseText>
            <BaseInput
              v-model="lensForm.title"
              placeholder="Lens Title"
            />
            <BaseInput
              v-model="lensForm.description"
              placeholder="Lens desc"
            />

            <button
              class="px-2 py-1 bg-black text-white rounded-md"
              @click="createLens"
            >
              Create
            </button>
            <div
              v-for="lens in lenses"
              :key="lens.id"
              class="flex flex-col gap-1 border border-border-normal rounded-md cursor-pointer"
              :class="lens.id === lensId ? 'bg-green-300' : ''"
              @click="updateLensId(lens.id)"
            >
              {{ lens.name }}
            </div>
          </div>
          <div class="p-2 gap-1 flex flex-col w-full h-full rounded-md border border-border-normal col-span-4">
            <BaseText>Step #2 - Create/Select a report</BaseText>
            <BaseInput
              v-model="reportForm.name"
              placeholder="Report Title"
            />
            <BaseInput
              v-model="reportForm.description"
              placeholder="Report desc"
            />

            <button
              class="px-2 py-1 bg-black text-white rounded-md"
              @click="createReport"
            >
              Create
            </button>
            <div
              v-for="report in reports"
              :key="report.id"
              class="flex flex-col gap-1 border border-border-normal rounded-md cursor-pointer"
              @click="openReport(report.id)"
            >
              {{ report.id }}
            </div>
          </div>
          <div class="p-4">
            <button
              class="px-2 py-1 bg-black text-white rounded-md w-max"
              @click="openReportModal"
            >
              Open create report modal
            </button>
            <button
              class="px-2 py-1 bg-red-300 text-white rounded-md w-max"
              @click="delReport"
            >
              Delete report
            </button>
          </div>
        </div>
      </div>
      <NewReportModal
        v-if="showReportModal"
        :lens-id="lensId"
        @close="showReportModal = false"
      />
    </template>
  </MainLayout>
</template>
<script>
import LensAPI from '@/api/lens'
// Components
import MainLayout from '../../layouts/MainLayout.vue'
import NewReportModal from '../../components/lens/NewReportModal.vue'

export default {
  name: 'LensTest',
  components: {
    MainLayout,
    NewReportModal
  },
  data () {
    return {
      // dev
      hideTodos: false,
      showReportModal: false,
      lensForm: {
        title: '',
        description: ''
      },
      reportForm: {
        name: '',
        description: ''
      },

      lensId: null,
      reportId: null,
      lenses: [],
      reports: []
    }
  },
  async mounted () {
    this.lenses = (await LensAPI.Lens.getAll())?.data
  },
  methods: {
    async updateLensId (lensId) {
      this.lensId = lensId
      this.reports = (await LensAPI.LensReport.getAll({ lens_id: this.lensId })).data
    },
    async createLens () {
      const lensPayload = {
        name: this.lensForm.title,
        description: this.lensForm.description,
        company_name: 'Acme Corp',
        currency: 'USD',
        timezone: 'America/New_York',
        goal_metric: 'ROAS',
        spend_threshold: 1000,
        click_attribution_window: '7d',
        view_attribution_window: '1d',
        niche: 'E-commerce',
        aov_min: 50,
        aov_max: 200,
        gmv: 1000000,
        created_at: '2024-02-03T12:00:00Z'
      }
      this.lensId = await LensAPI.Lens.create(lensPayload)
      this.lenses = (await LensAPI.Lens.getAll()).data
    },
    openReport (reportId) {
      this.reportId = reportId
      const url = process.env.VUE_APP_BASE_URL +
        '/lens/' +
        encodeURIComponent(this.lensId) +
        '/report/' +
        encodeURIComponent(reportId)

      window.open(url, '_blank')
    },
    async createReport () {
      const defaultTableCols = [{ key: 'spend', is_pinned: false }, { key: 'ctr', is_pinned: false }, { key: 'reach', is_pinned: false }]

      const reportPayload = {
        ...this.reportForm,
        lens_id: this.lensId,
        filters: [],
        table_columns: defaultTableCols,
        group_by: 'ad_name',
        selected_columns: {
          bar: [],
          line: [],
          grid: []
        },
        selected_graph_rows: {
          bar: 0,
          line: 0,
          grid: 0
        },
        sorted_column: { by: 'spend', order: 'desc' },
        table_config: {
          showStatus: false,
          showTags: false,
          colorFormat: null
        }
      }
      await LensAPI.LensReport.create(reportPayload)
      this.updateLensId(this.lensId)
    },
    async delReport () {
      await LensAPI.LensReport.remove(this.reportId)
      this.$showAlert({ type: 'success', message: 'Deleted report' })
    },
    openReportModal () {
      if (this.lensId) {
        this.showReportModal = true
      } else {
        this.$showAlert({ type: 'error', message: 'Create/Select a lens first' })
      }
    }
  }
}
</script>

<style scoped>
.dropdown-scrollable {
  transition: colors 250ms ease-in-out;
  top: 100%;
  z-index: 999;
}

.dropdown-scrollable::-webkit-scrollbar {
  width: 3px;
}

.dropdown-scrollable::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 18px;
}

.base-btn {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: box-shadow 100ms ease-in-out, background-color 100ms ease-in-out;
  border-radius: 6px;
  padding: 6px 12px 6px 6px;
}

.base-btn:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.17), 0px 0px 0px 1px rgba(0, 56, 108, 0.12);
}
</style>
